(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/components/selfservice-cart/assets/javascripts/reducers/cart-open-reducer.js') >= 0) return;  svs.modules.push('/components/components/selfservice-cart/assets/javascripts/reducers/cart-open-reducer.js');
"use strict";


const {
  OPEN_CART,
  CLOSE_CART
} = svs.components.selfserviceCart.reduxActions;
function cartOpenReducer() {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
  let action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case OPEN_CART:
      return true;
    case CLOSE_CART:
      return false;
    default:
      return state;
  }
}
setGlobal('svs.components.selfserviceCart.reducers.cartOpenReducer', cartOpenReducer);

 })(window);