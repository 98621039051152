(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/components/selfservice-cart/assets/javascripts/full-cart/empty-cart.js') >= 0) return;  svs.modules.push('/components/components/selfservice-cart/assets/javascripts/full-cart/empty-cart.js');
"use strict";


const {
  ReactButton,
  ReactIcon
} = svs.ui;
const EmptyCart = _ref => {
  let {
    onPlayMore
  } = _ref;
  return React.createElement("div", {
    className: "empty-cart-container"
  }, React.createElement("h1", {
    className: "f-900 f-prominent f-center"
  }, "Din varukorg \xE4r tom"), React.createElement(ReactButton, {
    className: "fat-ss-btn qa-play-more-empty",
    iconPosition: "left",
    onClick: onPlayMore,
    size: 300,
    transparent: true
  }, React.createElement(ReactIcon, {
    icon: "plus"
  }), "L\xE4gg till fler spel"));
};
setGlobal('svs.components.selfserviceCart.fullCart.EmptyCart', EmptyCart);

 })(window);