(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/components/selfservice-cart/assets/javascripts/full-cart/cart-item.js') >= 0) return;  svs.modules.push('/components/components/selfservice-cart/assets/javascripts/full-cart/cart-item.js');
"use strict";



const {
  format
} = svs.utils;
const {
  ReactButton,
  ReactIcon,
  ReactBrand
} = svs.ui;
const {
  svsconfig
} = svs.core.config.data;
const {
  useContext
} = React;
const {
  useSelector
} = ReactRedux;
const {
  FullCartContext
} = svs.components.selfserviceCart.fullCart;
const CartItem = _ref => {
  let {
    id,
    tenant
  } = _ref;
  const {
    removeItem
  } = useContext(FullCartContext);
  const item = useSelector(state => state.items.filter(item => item.id === id))[0];
  const {
    productProperties
  } = item;
  const handleClickDelete = item => {
    const dialog = new svs.components.dialog.Confirm({
      branding: svs.components.dialog.branding.NEUTRAL,
      title: 'Ta bort',
      icon: 'dustbin',
      area: svs.components.dialog.area.POPUP,
      autoClose: 0,
      type: svs.components.dialog.type.DEFAULT,
      message: [{
        type: svs.components.dialog.message.TEXT,
        text: 'Är du säker på att du vill ta bort spelet?',
        align: svs.components.dialog.message.align.CENTER
      }],
      actions: [{
        title: 'Avbryt'
      }, {
        title: 'Ta bort',
        showLoader: true,
        callback() {
          svs.components.analytics.resolveAndAddEcommerceEvent({
            eventLabel: 'remove_from_cart',
            items: [item]
          });
          removeItem(id);
          dialog.close();
        }
      }]
    });
    svs.components.dialog.api.add(dialog);
  };
  const rootClassNames = "cart-item cart-item-".concat(tenant);
  return React.createElement("div", {
    className: rootClassNames
  }, React.createElement("div", {
    className: "cart-item-corner"
  }, React.createElement("img", {
    src: "".concat(svsconfig.cdn + (tenant === 'lb' ? '/images/components/ao-logotype-soc/ao-soc-notext.svg' : '/images/components/ao-logotype-tur/ao-tur-notext.svg'))
  })), React.createElement(ReactBrand, {
    brand: productProperties.productBrand,
    square: true
  }), React.createElement("div", {
    className: "cart-item-product-name"
  }, productProperties.productTitle), React.createElement("div", {
    className: "cart-item-product-description"
  }, productProperties.description), React.createElement("span", {
    className: "cart-item-product-cost"
  }, format.Currency(productProperties.cost), ' ', "kr"), React.createElement(ReactButton, {
    className: "qa-delete-bet",
    iconPosition: "left",
    onClick: () => handleClickDelete(item),
    size: 300,
    transparent: true
  }, React.createElement(ReactIcon, {
    icon: "dustbin"
  }), "Ta bort"));
};
setGlobal('svs.components.selfserviceCart.fullCart.CartItem', CartItem);

 })(window);