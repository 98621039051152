(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/components/selfservice-cart/assets/javascripts/generate-product-item.js') >= 0) return;  svs.modules.push('/components/components/selfservice-cart/assets/javascripts/generate-product-item.js');
"use strict";


const {
  log
} = svs.core;
const {
  productIds,
  resolveProductId
} = svs.utils.products;
const {
  getDisplayName,
  getProductBrandName
} = svs.components.selfserviceCart.products;
const QUICKPICK_VARIANTS = ['QUICKPICK', 'PARTIALQP', 'WEIGHTEDQP', 'MODIFIEDQP', 'XPERTEN'];
const PRODUCT_PATH_TO_QUICKPICK = {
  [productIds.STRYKTIPSET]: ['bets', 'game1X2Boards', 'boardProps'],
  [productIds.EUROPATIPSET]: ['bets', 'game1X2Boards', 'boardProps'],
  [productIds.T8STRYK]: ['bets', 'game1X2Boards', 'boardProps'],
  [productIds.T8EXTRA]: ['bets', 'game1X2Boards', 'boardProps'],
  [productIds.FULLTRAFF]: ['bets', 'scoreBoards', 'boardProps'],
  [productIds.T8]: ['bets', 'game1X2Boards', 'boardProps'],
  [productIds.POWERPLAY]: ['bets', 'game1X2Boards', 'boardProps'],
  [productIds.MATCHEN]: ['bets', 'betProperties'],
  [productIds.BOMBEN]: ['bets', 'betProperties'],
  [productIds.MALTIPSET]: ['bets', 'numberBoards', 'boardProps'],
  [productIds.RACING]: ['bets', 'rxBoards', 'boardProps'],
  [productIds.CHALLENGE]: ['bets', 'game1X2Boards', 'boardProps'],
  [productIds.LOTTO]: ['bets', 'lottoBoards', 'boardProps'],
  [productIds.LOTTO_LOR]: ['bets', 'lottoBoards', 'boardProps'],
  [productIds.LOTTO_ONS]: ['bets', 'lottoBoards', 'boardProps'],
  [productIds.EUROJACKPOT]: ['bets', 'extNumberBoards', 'boardProps'],
  [productIds.KENO]: ['bets', 'kenoBoards', 'boardProps'],
  [productIds.VIKINGLOTTO]: ['bets', 'extNumberBoards', 'boardProps']
};
const searchPathRecursive = (currentProperty, remainingPath) => {
  if (!currentProperty) {
    return false;
  }
  if (remainingPath.length === 0) {
    if (Array.isArray(currentProperty)) {
      for (const qpValue of currentProperty) {
        if (QUICKPICK_VARIANTS.indexOf(qpValue) >= 0) {
          return true;
        }
      }
    }
    return false;
  }
  if (Array.isArray(currentProperty)) {
    return currentProperty.some(value => searchPathRecursive(value[remainingPath[0]], remainingPath.slice(1)));
  }
  return searchPathRecursive(currentProperty[remainingPath[0]], remainingPath.slice(1));
};
const findIsQuickPick = (wagers, pathToBoardPropsArray) => {
  if (!Array.isArray(wagers) || !Array.isArray(pathToBoardPropsArray)) {
    return false;
  }
  return wagers.some(wager => searchPathRecursive(wager, pathToBoardPropsArray));
};

const wagerBetsIncludesQuickPick = (wagers, productId) => findIsQuickPick([wagers], PRODUCT_PATH_TO_QUICKPICK[productId]);
const generateProductItem = _ref => {
  let {
    wagers,
    headers,
    isLive,
    productFlag,
    productTitle,
    productBrand,
    cost,
    description,
    addonProductName
  } = _ref;
  const logger = log.getLogger('selfservicecart:generateproductitem');
  if (!wagers || !productFlag || !cost) {
    logger.warn(new Error('Failed to generate productItem because its lacking one or all required fields'));
    return;
  }
  const productItem = {
    wagerString: null,
    wagers: null,
    payload: null,
    headers,
    isLive,
    productProperties: {
      productFlag,
      productBrand: productBrand && productBrand.length > 0 ? productBrand : getProductBrandName(productFlag),
      productTitle: productTitle && productTitle.length > 0 ? productTitle : getDisplayName(productFlag),
      cost,
      addonProductName,
      description,
      isQuickPick: wagerBetsIncludesQuickPick(wagers, resolveProductId(productFlag))
    }
  };
  if (typeof wagers === 'string') {
    productItem.wagerString = wagers;
    productItem.payload = productItem.wagerString;
  } else {
    productItem.wagers = Array.isArray(wagers) ? wagers : [wagers];
    productItem.payload = productItem.wagers;
  }
  return productItem;
};
setGlobal('svs.components.selfserviceCart.generateProductItem', generateProductItem);

 })(window);