(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/components/selfservice-cart/assets/javascripts/full-cart/cart-nav.js') >= 0) return;  svs.modules.push('/components/components/selfservice-cart/assets/javascripts/full-cart/cart-nav.js');
"use strict";


const CartNav = _ref => {
  let {
    leftComp,
    rightComp,
    className = ''
  } = _ref;
  return React.createElement("div", {
    className: ['ss-cart-container-nav', className].join(' ')
  }, React.createElement("div", {
    className: "ss-cart-container-nav-left"
  }, leftComp), React.createElement("div", {
    className: "ss-cart-container-nav-right"
  }, rightComp));
};
setGlobal('svs.components.selfserviceCart.fullCart.CartNav', CartNav);

 })(window);