(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/components/selfservice-cart/assets/javascripts/is-domain-up-and-running.js') >= 0) return;  svs.modules.push('/components/components/selfservice-cart/assets/javascripts/is-domain-up-and-running.js');
"use strict";

const isDomainUpAndRunning = _ref => {
  let {
    domainUrl,
    successCallbackFn,
    failedCallbackFn,
    log,
    retryCount = 5,
    standOffTime = [100, 250, 500, 1000]
  } = _ref;
  if (!domainUrl || !successCallbackFn && !failedCallbackFn) {
    return;
  }
  let failedCount = 0;
  const sendXhr = () => {
    const xhr = new XMLHttpRequest();
    xhr.onreadystatechange = e => {
      if (e.target.readyState === XMLHttpRequest.DONE) {
        if (e.target.status >= 200 && e.target.status < 300) {
          successCallbackFn && successCallbackFn(e.target.status);
        } else {
          failedCount++;
          if (failedCount <= retryCount) {
            const standOffIndex = standOffTime.length > failedCount ? failedCount - 1 : standOffTime.length - 1;
            const timeoutMs = standOffTime[standOffIndex];
            log && log.info("Failed[".concat(failedCount, "] to find \"").concat(domainUrl, "\" got status \"").concat(e.target.status, ". Retrying in ").concat(timeoutMs, "\""));
            setTimeout(sendXhr, timeoutMs);
          } else {
            log && log.warn("Failed[".concat(failedCount, "] to find \"").concat(domainUrl, "\" got status \"").concat(e.target.status, "\". Stopped retrying"));
            failedCallbackFn && failedCallbackFn();
          }
        }
      }
    };
    xhr.open('GET', domainUrl);
    xhr.send();
  };
  sendXhr();
};
setGlobal('svs.components.selfserviceCart.isDomainUpAndRunning', isDomainUpAndRunning);

 })(window);