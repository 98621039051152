(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/components/selfservice-cart/assets/javascripts/products.js') >= 0) return;  svs.modules.push('/components/components/selfservice-cart/assets/javascripts/products.js');
"use strict";

const {
  getProductClassName,
  getDisplayName
} = svs.utils.products;
const bagType = {
  lordagsgodis: 'Lördagsgodis',
  rubbet: 'Rubbet'
};
const products = {
  getProductBrandName: anyInput => {
    if (!anyInput) {
      return '';
    }
    const anyInputLower = "".concat(anyInput).toLowerCase();
    if (bagType[anyInputLower]) {
      return anyInputLower;
    }
    return getProductClassName(anyInput);
  },
  getDisplayName: anyInput => {
    if (!anyInput) {
      return '';
    }
    const anyInputLower = "".concat(anyInput).toLowerCase();
    if (bagType[anyInputLower]) {
      return bagType[anyInputLower];
    }
    return getDisplayName(anyInput);
  }
};
setGlobal('svs.components.selfserviceCart.products', products);

 })(window);