(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/components/selfservice-cart/assets/javascripts/reducers/root-reducer.js') >= 0) return;  svs.modules.push('/components/components/selfservice-cart/assets/javascripts/reducers/root-reducer.js');
"use strict";


const {
  combineReducers
} = RTK;
const {
  itemsReducer,
  cartOpenReducer
} = svs.components.selfserviceCart.reducers;
const rootReducer = combineReducers({
  items: itemsReducer,
  cartOpen: cartOpenReducer
});
setGlobal('svs.components.selfserviceCart.reducers.rootReducer', rootReducer);

 })(window);