(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/components/selfservice-cart/assets/javascripts/prescript.js') >= 0) return;  svs.modules.push('/components/components/selfservice-cart/assets/javascripts/prescript.js');

const constants = {
  TENANT_LB: 'lb',
  CONTEXT: 'selfservice',
  GAMECALLBACK_NOGAME: 0,
  GAMECALLBACK_HASINCOMPLETEGAME: 1,
  GAMECALLBACK_HASCOMPLETEGAME: 2
};

const logger = svs.core.log.getLogger('selfservice-cart');

setGlobal('svs.components.selfserviceCart.constants', constants);
setGlobal('svs.components.selfserviceCart.logger', logger);


 })(window);