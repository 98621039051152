(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/components/selfservice-cart/assets/javascripts/full-cart/cart.js') >= 0) return;  svs.modules.push('/components/components/selfservice-cart/assets/javascripts/full-cart/cart.js');
"use strict";



const {
  CartNavFooter,
  CartNavHeader,
  CartItemList,
  CartInfo,
  EmptyCart
} = svs.components.selfserviceCart.fullCart;
const {
  closeCart
} = svs.components.selfserviceCart.reduxActions;
const {
  useDispatch,
  useSelector
} = ReactRedux;
function getTotalCost(items) {
  let cost = 0;
  items.forEach(item => {
    cost += item.productProperties.cost;
  });
  return cost;
}
const Cart = _ref => {
  let {
    onPlayMore,
    onFinished
  } = _ref;
  const items = useSelector(state => state.items);
  const productCount = items ? items.length : 0;
  const totalPrice = items ? getTotalCost(items) : 0;
  const dispatch = useDispatch();
  const isOpen = useSelector(state => state.cartOpen);
  function onClose() {
    dispatch(closeCart());
  }
  const rootClasses = "ss-cart-container".concat(isOpen ? ' ss-cart-container-open' : '');
  return React.createElement("div", {
    className: rootClasses
  }, React.createElement(CartNavHeader, {
    numItems: productCount,
    onClose: onClose
  }), React.createElement("div", {
    className: "ss-cart-center"
  }, productCount > 0 ? React.createElement(React.Fragment, null, React.createElement("div", {
    className: "ss-cart-items"
  }, React.createElement("div", {
    className: "ss-cart-items-inner"
  }, React.createElement(CartItemList, {
    tenant: "tb"
  }), React.createElement(CartItemList, {
    tenant: "lb"
  }))), React.createElement(CartInfo, {
    amountOfItems: productCount,
    totalItemCost: totalPrice
  })) : React.createElement(EmptyCart, {
    onPlayMore: onPlayMore
  })), productCount > 0 && React.createElement(CartNavFooter, {
    onFinished: () => onFinished(items),
    onPlayMore: onPlayMore
  }));
};
setGlobal('svs.components.selfserviceCart.fullCart.Cart', Cart);

 })(window);