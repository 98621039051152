(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/components/selfservice-cart/assets/javascripts/create-context.js') >= 0) return;  svs.modules.push('/components/components/selfservice-cart/assets/javascripts/create-context.js');
"use strict";

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
const {
  constants,
  getLocalFakeFrame,
  data: selfserviceCartData,
  fns: {
    actions: actionFunctions
  },
  createStore,
  reducers,
  reduxActions
} = svs.components.selfserviceCart;
const {
  data: coreData
} = svs.core;
const createContext = cartRequestRef => {
  const store = createStore(reducers.rootReducer, undefined, []);
  const showErrorDialog = callbackFn => {
    const dialogActions = [{
      title: svs.components.selfserviceCart.data.isProd ? 'Ok' : 'Försök igen',
      callback() {
        if (callbackFn) {
          callbackFn();
        }
        svs.components.dialog.api.close();
      }
    }];
    if (!svs.components.selfserviceCart.data.isProd) {
      dialogActions.push({
        title: 'Avbryt',
        callback() {
          svs.components.dialog.api.close();
        }
      });
    }
    const errorDialog = new svs.components.dialog.Confirm({
      branding: svs.components.dialog.branding.NEUTRAL,
      title: 'Något gick fel. Vänligen försök igen senare.',
      icon: 'exclamation-sign',
      allowDimmerClick: false,
      type: svs.components.selfserviceCart.data.isProd ? svs.components.dialog.type.ERROR : svs.components.dialog.type.DEFAULT,
      actions: dialogActions
    });
    svs.components.dialog.api.add(errorDialog);
  };
  const requests = Object.keys(actionFunctions).reduce((acc, fn) => _objectSpread(_objectSpread({}, acc), {}, {
    [fn]: resp => {
      let callbackFn = null;
      const reqId = resp.requestId;
      if (reqId && cartRequestRef[reqId]) {
        if (cartRequestRef[reqId].timeoutId) {
          clearTimeout(cartRequestRef[reqId].timeoutId);
        }
        callbackFn = cartRequestRef[reqId].callbackFn;
        delete cartRequestRef[reqId];
      }
      store.dispatch(reduxActions.replaceItems(resp.cartObject));
      if (callbackFn) {
        callbackFn();
      }
    }
  }), {});
  let src = '';
  let sendRequest = null;
  let destroy = () => {};
  if (coreData.tenant === constants.TENANT_LB) {
    let isLoaded = false;
    let hasIssues = false;
    let iframe;
    const queue = [];
    const tbDomain = new URL(selfserviceCartData.tbSelfserviceCartUrl);
    tbDomain.searchParams.set('ignoreTimer', true);
    src = tbDomain.toString();
    const {
      isDomainUpAndRunning
    } = svs.components.selfserviceCart;
    isDomainUpAndRunning({
      log: svs.components.selfserviceCart.logger,
      domainUrl: src,
      successCallbackFn: () => {
        iframe = document.createElement('iframe');
        iframe.src = src;
        iframe.style.display = 'none';
        iframe.addEventListener('load', () => {
          isLoaded = true;
          hasIssues = false;
          const ql = queue.length;
          if (ql > 0) {
            for (let i = 0; i < ql; ++i) {
              iframe.contentWindow.postMessage(...queue[i]);
            }
          }
        });
        document.body.appendChild(iframe);
      },
      failedCallbackFn: () => {
        hasIssues = true;
        const ql = queue.length;
        if (ql > 0) {
          showErrorDialog(() => {
            window.location.reload();
          });
        }
      }
    });
    sendRequest = function () {
      for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }
      if (hasIssues) {
        throw new Error('Cant sendRequest because of issues');
      } else if (!isLoaded) {
        queue.push([...args]);
        return;
      } else if (!iframe) {
        throw new Error('Cant sendRequest because lacking iframe');
      }
      iframe.contentWindow.postMessage(...args);
    };
    destroy = () => {
      if (iframe) {
        document.body.removeChild(iframe);
        iframe = null;
      }
    };
  } else {
    sendRequest = getLocalFakeFrame(requests);
  }
  return {
    destroy,
    store,
    requests,
    src,
    sendRequest,
    showErrorDialog
  };
};
setGlobal('svs.components.selfserviceCart.createContext', createContext);

 })(window);