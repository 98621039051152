(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/components/selfservice-cart/assets/javascripts/create-store.js') >= 0) return;  svs.modules.push('/components/components/selfservice-cart/assets/javascripts/create-store.js');
"use strict";


const {
  createStore: reduxCreateStore,
  applyMiddleware,
  compose
} = RTK;
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const createStore = function (reducer) {
  let initialState = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  let middleware = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];
  const enhancer = applyMiddleware(...middleware);
  const store = reduxCreateStore(reducer, initialState, composeEnhancers(enhancer));
  return store;
};
setGlobal('svs.components.selfserviceCart.createStore', createStore);

 })(window);