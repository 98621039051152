(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/components/selfservice-cart/assets/javascripts/cart.js') >= 0) return;  svs.modules.push('/components/components/selfservice-cart/assets/javascripts/cart.js');
"use strict";

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
const {
  selfserviceCart
} = svs.components;
const {
  constants,
  logger
} = svs.components.selfserviceCart;
let cartInstance = null;
let cartRequestCount = 0;
const cartRequestRef = {};
let gameCallback = () => constants.GAMECALLBACK_NOGAME;
const getCartInstance = () => {
  if (cartInstance) {
    return cartInstance;
  }
  const {
    createContext,
    setupListener,
    fullCart,
    fns: {
      actions: actionFunctions
    }
  } = selfserviceCart;
  const {
    requests,
    sendRequest,
    src,
    store,
    destroy: destroyContext,
    showErrorDialog
  } = createContext(cartRequestRef);
  const actions = Object.keys(actionFunctions).reduce((acc, fn) => _objectSpread(_objectSpread({}, acc), {}, {
    [fn]: actionFunctions[fn]((data, callback, timeout) => {
      const requestId = ++cartRequestCount;
      callback = typeof callback === 'function' ? callback : () => {};
      timeout = timeout > 0 ? timeout : 30000;
      const internalCallback = err => {
        if (err) {
          showErrorDialog(() => {
            window.location.reload();
          });
        }
        callback(err);
      };
      cartRequestRef[requestId] = {
        callbackFn: internalCallback,
        timeoutId: setTimeout(() => {
          internalCallback(new Error('Callback timeout'));
        }, timeout)
      };
      try {
        sendRequest({
          data,
          requestId,
          name: fn,
          tenant: svs.core.data.tenant,
          context: constants.CONTEXT
        }, src);
      } catch (err) {
        logger.warn("Failed to execute sendRequest \"".concat(err.message, "\""));
        clearTimeout(cartRequestRef[requestId].timeoutId);
        internalCallback(err);
      }
    })
  }), {});
  const unlisten = setupListener(src, requests);
  const setGameCallback = fn => {
    if (typeof fn === 'function') {
      gameCallback = fn;
    }
  };
  const runCheckout = _ref => {
    let {
      cartObj,
      onSuccessCallback,
      onErrorAbortCallback,
      onFinishCallback
    } = _ref;
    svs.components.selfservice.container.openCheckout({
      cartObj,
      onSuccessCallback,
      onErrorAbortCallback,
      onFinishCallback
    });
  };
  const checkout = _ref2 => {
    let {
      cartObj,
      onSuccessCallback,
      onErrorAbortCallback,
      onFinishCallback
    } = _ref2;
    const gameResult = gameCallback();
    if (gameResult === constants.GAMECALLBACK_HASINCOMPLETEGAME || gameResult === constants.GAMECALLBACK_HASCOMPLETEGAME) {
      const [title, textRow1, textRow2] = {
        [constants.GAMECALLBACK_HASCOMPLETEGAME]: ['Färdigt spel', 'Du har ett färdigt spel som du inte lagt i varukorgen.', 'Vill du fortsätta till kassan utan detta spel?'],
        [constants.GAMECALLBACK_HASINCOMPLETEGAME]: ['Påbörjat spel', 'Du har ett påbörjat spel.', 'Vill du fortsätta till kassan utan att färdigställa detta spel?']
      }[gameResult];
      const dialog = new svs.components.dialog.Confirm({
        branding: svs.components.dialog.branding.NEUTRAL,
        title,
        icon: 'exclamation-sign',
        area: svs.components.dialog.area.POPUP,
        autoClose: 0,
        type: svs.components.dialog.type.DEFAULT,
        message: [{
          type: svs.components.dialog.message.TEXT,
          text: textRow1,
          align: svs.components.dialog.message.align.CENTER
        }, {
          type: svs.components.dialog.message.TEXT,
          text: textRow2,
          align: svs.components.dialog.message.align.CENTER
        }],
        actions: [{
          title: 'Avbryt',
          callback() {
            svs.components.analytics.trackEvent({
              action: 'pop_up',
              category: 'färdigt spel',
              opt_label: 'cancel'
            });
            dialog.close();
          }
        }, {
          title: 'Fortsätt',
          showLoader: true,
          callback() {
            svs.components.analytics.trackEvent({
              action: 'pop_up',
              category: 'färdigt spel',
              opt_label: 'continue'
            });
            dialog.close();
            runCheckout({
              cartObj,
              onSuccessCallback,
              onErrorAbortCallback,
              onFinishCallback
            });
          }
        }]
      });
      svs.components.dialog.api.add(dialog);
    } else {
      runCheckout({
        cartObj,
        onSuccessCallback,
        onErrorAbortCallback,
        onFinishCallback
      });
    }
  };
  const destroy = () => {
    unlisten();
    destroyContext();
    cartInstance = null;
  };
  actions.getItems();
  cartInstance = {
    getStore: () => store,
    actions,
    destroy,
    setGameCallback,
    checkout
  };
  fullCart.initialize(cartInstance);
  return cartInstance;
};
setGlobal('svs.components.selfserviceCart.getCartInstance', getCartInstance);

 })(window);