(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/components/selfservice-cart/assets/javascripts/full-cart/cart-item-list.js') >= 0) return;  svs.modules.push('/components/components/selfservice-cart/assets/javascripts/full-cart/cart-item-list.js');
"use strict";



const {
  CartItem
} = svs.components.selfserviceCart.fullCart;
const {
  svsconfig
} = svs.core.config.data;
const {
  useSelector
} = ReactRedux;
const tenantNames = {
  lb: 'Svenska Spel Sport & Casino',
  tb: 'AB Svenska Spel'
};
const CartItemList = _ref => {
  let {
    tenant
  } = _ref;
  const rootClassNames = "cart-item-list cart-item-list-".concat(tenant);
  const items = useSelector(state => state.items.filter(item => item.productProperties.tenant === tenant));
  if (items.length === 0) {
    return null;
  }
  return React.createElement("div", {
    className: rootClassNames
  }, React.createElement("div", {
    className: "cart-item-list-corner"
  }, React.createElement("img", {
    src: "".concat(svsconfig.cdn + (tenant === 'lb' ? '/images/components/ao-logotype-soc/ao-soc.svg' : '/images/components/ao-logotype-tur/ao-tur.svg'))
  })), React.createElement("div", {
    className: "cart-item-list-name"
  }, "Valda spel:", ' ', React.createElement("span", {
    className: "f-bold"
  }, tenantNames[tenant])), items.map(_ref2 => {
    let {
      id
    } = _ref2;
    return React.createElement(CartItem, {
      id: id,
      key: id,
      tenant: tenant
    });
  }));
};
setGlobal('svs.components.selfserviceCart.fullCart.CartItemList', CartItemList);

 })(window);