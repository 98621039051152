(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/components/selfservice-cart/assets/javascripts/full-cart/initialize.js') >= 0) return;  svs.modules.push('/components/components/selfservice-cart/assets/javascripts/full-cart/initialize.js');
"use strict";



const {
  Cart,
  FullCartContext
} = svs.components.selfserviceCart.fullCart;
const {
  Provider
} = ReactRedux;
function onFinished(items) {
  svs.components.analytics.resolveAndAddEcommerceEvent({
    eventLabel: 'begin_checkout',
    items
  });
  svs.components.selfserviceCart.getCartInstance().checkout({
    cartObj: items,
    onSuccessCallback: () => {
      svs.components.selfserviceCart.clearStorage();
    },
    onErrorAbortCallback: () => {},
    onFinishCallback: () => {}
  });
}
function onPlayMore() {
  if (svs.components.selfserviceCart.data.selfserviceDividerLink) {
    window.location.href = svs.components.selfserviceCart.data.selfserviceDividerLink;
  }
}
function initialize(cartInstance) {
  const bigCartEl = document.createElement('div');
  document.body.appendChild(bigCartEl);
  ReactDOM.render(React.createElement(Provider, {
    store: cartInstance.getStore()
  }, React.createElement(FullCartContext.Provider, {
    value: cartInstance.actions
  }, React.createElement(Cart, {
    onFinished: onFinished,
    onPlayMore: onPlayMore
  }))), bigCartEl);
}
setGlobal('svs.components.selfserviceCart.fullCart.initialize', initialize);

 })(window);