(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/components/selfservice-cart/assets/javascripts/setup-listener.js') >= 0) return;  svs.modules.push('/components/components/selfservice-cart/assets/javascripts/setup-listener.js');
"use strict";


const {
  constants,
  logger
} = svs.components.selfserviceCart;
const {
  data: coreData
} = svs.core;
const setupListener = (src, callbacks) => {
  if (coreData.tenant === constants.TENANT_LB) {
    const messageEvent = _ref => {
      let {
        data,
        origin
      } = _ref;
      if (src.indexOf(origin) !== 0) {
        return;
      }
      const fn = callbacks[data.name];
      if (fn) {
        fn(data.data);
      } else {
        logger.info("Receiving event not responding to", data);
      }
    };
    window.addEventListener('message', messageEvent);
    return () => {
      window.removeEventListener('message', messageEvent);
    };
  }
  return () => {};
};
setGlobal('svs.components.selfserviceCart.setupListener', setupListener);

 })(window);