(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/components/selfservice-cart/assets/javascripts/redux-actions.js') >= 0) return;  svs.modules.push('/components/components/selfservice-cart/assets/javascripts/redux-actions.js');
"use strict";


const OPEN_CART = 'selfserviceCart/openCart';
function openCart() {
  return {
    type: OPEN_CART
  };
}
const CLOSE_CART = 'selfserviceCart/closeCart';
function closeCart() {
  return {
    type: CLOSE_CART
  };
}
const REPLACE_ITEMS = 'selfserviceCart/replaceItems';
function replaceItems(data) {
  return {
    type: REPLACE_ITEMS,
    payload: data
  };
}
setGlobal('svs.components.selfserviceCart.reduxActions', {
  OPEN_CART,
  openCart,
  CLOSE_CART,
  closeCart,
  REPLACE_ITEMS,
  replaceItems
});

 })(window);