(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/components/selfservice-cart/assets/javascripts/local-fake-frame.js') >= 0) return;  svs.modules.push('/components/components/selfservice-cart/assets/javascripts/local-fake-frame.js');
"use strict";

const {
  selfserviceStorageHandler
} = svs.components;
const {
  constants
} = svs.components.selfserviceCart;
const getLocalFakeFrame = requests => data => {
  if (!data || data.context !== constants.CONTEXT) {
    return;
  }
  const fn = selfserviceStorageHandler.actions[data.name];
  if (!fn || !requests[data.name]) {
    return;
  }
  fn(requests[data.name])(data.tenant, data.data, data.requestId);
};
setGlobal('svs.components.selfserviceCart.getLocalFakeFrame', getLocalFakeFrame);

 })(window);