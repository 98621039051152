(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/components/selfservice-cart/assets/javascripts/reducers/items-reducer.js') >= 0) return;  svs.modules.push('/components/components/selfservice-cart/assets/javascripts/reducers/items-reducer.js');
"use strict";


const {
  REPLACE_ITEMS
} = svs.components.selfserviceCart.reduxActions;
function itemsReducer() {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
  let action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case REPLACE_ITEMS:
      return action.payload;
    default:
      return state;
  }
}
setGlobal('svs.components.selfserviceCart.reducers.itemsReducer', itemsReducer);

 })(window);