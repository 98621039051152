(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/components/selfservice-cart/assets/javascripts/full-cart/cart-nav-footer.js') >= 0) return;  svs.modules.push('/components/components/selfservice-cart/assets/javascripts/full-cart/cart-nav-footer.js');
"use strict";


const {
  ReactButton,
  ReactIcon
} = svs.ui;
const {
  CartNav
} = svs.components.selfserviceCart.fullCart;
const CartNavFooter = _ref => {
  let {
    onPlayMore,
    onFinished
  } = _ref;
  return React.createElement(CartNav, {
    leftComp: React.createElement(ReactButton, {
      className: "fat-ss-btn qa-play-more",
      iconPosition: "left",
      onClick: onPlayMore,
      size: 300,
      transparent: true
    }, React.createElement(ReactIcon, {
      icon: "plus"
    }), "L\xE4gg till fler spel"),
    rightComp: React.createElement(ReactButton, {
      className: "fat-ss-btn qa-send",
      iconPosition: "left",
      inverted: true,
      onClick: onFinished,
      size: 300
    }, React.createElement(ReactIcon, {
      icon: "arrow-right"
    }), "Skicka spel till kassan")
  });
};
setGlobal('svs.components.selfserviceCart.fullCart.CartNavFooter', CartNavFooter);

 })(window);