(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/components/selfservice-cart/assets/javascripts/full-cart/cart-nav-header.js') >= 0) return;  svs.modules.push('/components/components/selfservice-cart/assets/javascripts/full-cart/cart-nav-header.js');
"use strict";


const {
  ReactButton,
  ReactIcon
} = svs.ui;
const {
  useContext
} = React;
const {
  FullCartContext,
  CartNav
} = svs.components.selfserviceCart.fullCart;
const {
  useSelector
} = ReactRedux;
const CartNavHeader = _ref => {
  let {
    onClose,
    numItems
  } = _ref;
  const {
    clearItems
  } = useContext(FullCartContext);
  const items = useSelector(state => state.items);
  function handleClickClear(items) {
    const dialog = new svs.components.dialog.Confirm({
      branding: svs.components.dialog.branding.NEUTRAL,
      title: 'Töm varukorg',
      icon: 'dustbin',
      area: svs.components.dialog.area.POPUP,
      autoClose: 0,
      type: svs.components.dialog.type.DEFAULT,
      message: [{
        type: svs.components.dialog.message.TEXT,
        text: 'Är du säker på att du vill tömma varukorgen?',
        align: svs.components.dialog.message.align.CENTER
      }],
      actions: [{
        title: 'Avbryt'
      }, {
        title: 'Töm',
        showLoader: true,
        callback() {
          svs.components.analytics.resolveAndAddEcommerceEvent({
            eventLabel: 'remove_from_cart',
            items
          });
          clearItems();
          dialog.close();
        }
      }]
    });
    svs.components.dialog.api.add(dialog);
  }
  return React.createElement(CartNav, {
    className: "ss-cart-nav-header",
    leftComp: React.createElement(React.Fragment, null, React.createElement(ReactIcon, {
      color: "fc-navy",
      icon: "selfservice-cart",
      size: "900"
    }), React.createElement("div", {
      className: "ss-cart-container-icon-items"
    }, numItems), React.createElement("div", {
      className: "ss-cart-container-title"
    }, "Varukorg")),
    rightComp: React.createElement(React.Fragment, null, numItems > 0 &&
    React.createElement(ReactButton, {
      className: "fat-ss-btn qa-clear-cart",
      iconPosition: "left",
      onClick: () => handleClickClear(items),
      size: 300,
      transparent: true
    }, React.createElement(ReactIcon, {
      icon: "dustbin"
    }), "T\xF6m varukorg"), React.createElement(ReactButton, {
      className: "fat-ss-btn qa-close-cart",
      iconPosition: "left",
      onClick: onClose,
      size: 300,
      transparent: true
    }, React.createElement(ReactIcon, {
      icon: "close"
    }), "St\xE4ng"))
  });
};
setGlobal('svs.components.selfserviceCart.fullCart.CartNavHeader', CartNavHeader);

 })(window);