(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/components/selfservice-cart/assets/javascripts/full-cart/cart-info.js') >= 0) return;  svs.modules.push('/components/components/selfservice-cart/assets/javascripts/full-cart/cart-info.js');
"use strict";


const {
  format
} = svs.utils;
const CartInfo = _ref => {
  let {
    amountOfItems,
    totalItemCost
  } = _ref;
  return React.createElement("div", {
    className: "ss-cart-product-info"
  }, React.createElement("div", {
    className: "ss-cart-product-info-section"
  }, "Spel:", ' ', React.createElement("span", {
    className: "ss-cart-product-info-value"
  }, amountOfItems)), React.createElement("div", {
    className: "ss-cart-product-info-section"
  }, "Summa:", ' ', React.createElement("span", {
    className: "ss-cart-product-info-value"
  }, format.Currency(totalItemCost), ' ', "kr")));
};
setGlobal('svs.components.selfserviceCart.fullCart.CartInfo', CartInfo);

 })(window);