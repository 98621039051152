(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/components/selfservice-cart/assets/javascripts/full-cart/full-cart-context.js') >= 0) return;  svs.modules.push('/components/components/selfservice-cart/assets/javascripts/full-cart/full-cart-context.js');
"use strict";


const {
  createContext
} = React;
const FullCartContext = createContext();
setGlobal('svs.components.selfserviceCart.fullCart.FullCartContext', FullCartContext);

 })(window);